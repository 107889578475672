<template>
  <div class="browseLesson">
    <!-- 头部 -->
    <headers ref="headers" :headname="headName"></headers>
    <!-- 列表 -->
    <div v-if="islistlength" class="listBox">
      <div v-for="(item,index) in list" :key="index" class="listItem">
        <img class="tb" src="../../../assets/image/ysy.png" alt="">
        <div class="listItemTop">
          <img :src="item.thumb" alt="">
          <p v-if="item.product_name.length>29" class="ptitlename">{{ `${item.product_name.substr(0,29)}...` }}</p>
          <p v-else class="ptitlename">{{ item.product_name }}</p>
        </div>
        <div class="listItemLine"></div>
        <div class="listItemBottom">
          <p>使用有效期至：{{ item.valid_end }}</p>
        </div>
      </div>
    </div>
    <div v-else class="qxtimg">
      <img style="margin:150px 0 0 0;" src="../../../assets/image/qxt.png" alt="">
      <p style="padding:20px 0 0 0;">您还没有已使用的兑换码~</p>
    </div>
  </div>
</template>

<script>
import headers from '@/components/topTitle/index.vue'
import { getlist, productinfo } from '@/api/exchangeCode.js'
export default {
  components: { headers },
  data() {
    return {
      headName: '已使用',
      list: [],
      islistlength: true
    }
  },

  computed: {},

  created() {
    this.getList()
  },

  mounted() {

  },

  methods: {
    // 列表
    getList() {
      var params = {
        type: 3
      }
      getlist(params).then(res => {
        if (res.errNo == 0) {
          this.list = res.result.list
          if (res.result.count == 0) {
            this.islistlength = false
          }
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.listItemBottom p:nth-child(2){
    color: #F40E0E;
    padding: 0px 12px 0 0px;
    cursor: pointer;
}
.listItemBottom{
  font-size: 14px;
  color: #999;
  padding: 12px 0 0 12px;
  display: flex;
  justify-content: space-between;
}
.listItemLine{
  width: 406px;
  height: 0.5px;
  margin: 0 0 0 12px;
  background: #E9E9E9;
}
.listItemTop{
  width: 100%;
  height: 104px;
  display: flex;
  text-align: left;
  img{
    width: 115px;
    height: 82px;
    display: block;
    margin: 12px 0 0 12px;
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
  }

}
.ptitlename{
    width: 270px;
    padding: 12px 0 0 7px;
    color: #999999;
    font-size: 17px;
    font-weight: 600;

  }
.listItem{
  border: 1px solid #EBEBEB;
  box-shadow: 0 2px 15px 8px #F8F8F8;
  width: 430px;
  height: 149px;
  margin: 0 0 30px 0;
  position: relative;
  border-radius: 10px;
}
.tb{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
}
.listBox{
  width: 890px;
  margin: 30px 0 0 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
